import React, { Component } from 'react';

class About extends Component {
   render() {

    if(this.props.data){
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
       var description = this.props.data.description;
       var desc2 = this.props.data.desc2;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src={profilepic} alt="" />
          </div>
          <div className="nine columns main-col">
            <h2>About</h2>
            <p>{bio}</p>
            <p>{description}</p>
            <p>{desc2}</p>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
